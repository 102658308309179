import { minmaxLength } from "../lib/minmax-length"

function name() {
  const regExp = new RegExp("[\\[\\]{}!\"£$%^&*()-=_+@~'#;:<>?\\/]")
  const isEmpty = this.$input.value === ""
  const { isMinMaxLengthValid } = minmaxLength(this.$input)

  if (isEmpty) {
    this.isValid = false

    this.$error.innerText = "This field must not be empty"

    return
  }

  if (this.$input.value.match(regExp)) {
    this.isValid = false

    this.$error.innerText = "The name must not include numbers or special characters."

    return
  }

  if (!isMinMaxLengthValid) {
    this.isValid = false

    this.$error.innerText = "The field must contain at least 2 letters"
    return
  }

  this.isValid = true
}

export { name }
