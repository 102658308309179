import Swiper from "swiper"
import { Navigation, Pagination } from "swiper/modules"
import { Autoplay } from "swiper/modules"

const $evetsSlider = document?.querySelector(".events"),
  $partnersSlider = document?.querySelector(".partners"),
  slideProposition = document?.querySelector(".proposition"),
  sliderHero = document?.querySelector(".hero")

export const sliders = () => {
  let sliderH = null
  let is_inited = false

  let productFunction = function () {
    if (sliderHero) {
      if (window.innerWidth <= 1200) {
        if (!is_inited) {
          sliderH = new Swiper(".hero", {
            modules: [Autoplay, Autoplay, Pagination],
            direction: "horizontal",
            loop: true,

            pagination: {
              el: ".hero .swiper-pagination",
              type: "bullets"
            },

            autoplay: {
              delay: 3000
            },

            speed: 700,
            slidesPerView: 4,
            slidesPerGroup: 4,

            breakpoints: {
              768: {
                slidesPerView: 2,
                slidesPerGroup: 2
              },
              320: {
                slidesPerView: 1,
                slidesPerGroup: 1
              }
            }
          })
        }
        is_inited = true
      } else {
        if (sliderH) {
          sliderH.destroy()
          is_inited = false
        }
      }
    }
  }

  window.addEventListener("resize", productFunction)
  productFunction()

  if ($evetsSlider && $partnersSlider) {
    new Swiper(".events .events__inner", {
      modules: [Navigation, Autoplay, Pagination],
      direction: "horizontal",
      loop: true,
      navigation: {
        nextEl: ".events .swiper-button-next",
        prevEl: ".events .swiper-button-prev"
      },

      pagination: {
        el: ".events .swiper-pagination",
        type: "bullets"
      },

      autoplay: {
        delay: 6000
      },

      speed: 700,
      slidesPerView: 4,
      slidesPerGroup: 4,

      breakpoints: {
        2000: {
          slidesPerView: 4,
          slidesPerGroup: 4
        },

        1400: {
          slidesPerView: 3,
          slidesPerGroup: 3,
          spaceBetween: 100
        },

        1100: {
          slidesPerView: 3,
          slidesPerGroup: 3,
          spaceBetween: 50
        },

        768: {
          slidesPerView: 2,
          slidesPerGroup: 2
        },

        320: {
          slidesPerView: 1,
          slidesPerGroup: 1
        }
      }
    })

    new Swiper(".partners", {
      // modules: [Autoplay],
      direction: "horizontal",
      loop: true,

      speed: 1200,
      spaceBetween: 58.5,
      slidesPerView: 3,

      breakpoints: {
        1700: {
          slidesPerView: 5
        },

        1150: {
          slidesPerView: 4,
          slidesPerGroup: 3
        }
      }
    })
  }

  if (slideProposition) {
    new Swiper(".proposition", {
      modules: [Navigation, Autoplay, Pagination],
      direction: "horizontal",
      loop: true,
      navigation: {
        nextEl: ".proposition .swiper-button-next",
        prevEl: ".proposition .swiper-button-prev"
      },

      pagination: {
        el: ".proposition .swiper-pagination",
        type: "bullets"
      },

      autoplay: {
        delay: 6000
      },

      speed: 700,
      slidesPerView: 4,
      slidesPerGroup: 4,

      breakpoints: {
        2000: {
          slidesPerView: 4,
          slidesPerGroup: 4
        },

        1400: {
          slidesPerView: 3,
          slidesPerGroup: 3
        },

        1100: {
          slidesPerView: 3,
          slidesPerGroup: 3
        },

        768: {
          slidesPerView: 2,
          slidesPerGroup: 2
        },

        320: {
          slidesPerView: 1,
          slidesPerGroup: 1
        }
      }
    })
  }
}
