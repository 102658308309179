export function submit(event) {
  event.preventDefault()
  this.isValid = true

  // === Validation form
  this.validate()

  /*
   * If form is valid send request with ajax
   */
  this.sendXML({
    onSuccess: (data) => {
      console.log("Fetch is success")
      console.log(data)

      this.reset()
    },
    onError: (data) => {
      console.log("Fetch with error")
      console.log(data)
    }
  })

  /*
   * If form is valid send request with fetch
   */
  // this.sendFetch({
  //   onSuccess: (data) => {
  //     console.log("Fetch is success")
  //     console.log(data)
  //
  //     this.reset()
  //   },
  //   onError: (data) => {
  //     console.log("Fetch with error")
  //     console.log(data)
  //   }
  // })
}
