import { select } from "./components/select"
import { accordion } from "./components/accordion"
import { header } from "./modules/header"
import { formValidation } from "./components/form-validation"
import { modal } from "./components/modal"
import { tabs } from "./components/tabs"
import { navigation } from "./modules/navigaton"
import Swiper, { Navigation, Pagination } from "swiper"
import { sliders } from "./components/swiper"
import { counter } from "./modules/product-counter"
import { sort } from "./modules/sort"
// import { sortByLocation } from "./modules/sort"


Swiper.use([Navigation, Pagination])

window.addEventListener("load", () => {
  // Show hidden elements
  document.querySelectorAll(".important_none")?.forEach(($el) => $el.classList.remove("important_none"))

  // ===== Components
  // All selects
  var selectsInstances = select()
  // console.log("selectsInstances", selectsInstances)

  // All accordions
  const accordionsInstances = accordion()
  // console.log('accordionsInstances', accordionsInstances);

  // Tabs
  const tabsInstance = tabs()
  // console.log('tabsInstance', tabsInstance);

  // Forms
  const formsInstances = formValidation()
  // console.log("formsInstances", formsInstances)

  // Modals
  const modalsInstance = modal()
  // console.log('modalsInstance', modalsInstance);

  // Sliders
  sliders()
  // ===== Components end

  // ===== Modules
  // Nav
  const navigationInstance = navigation()
  // console.log('navigationInstance', navigationInstance);

  // Header
  const headerInstance = header()
  // console.log('headerInstance', headerInstance);
  // ===== Modules end

  // Counter
  const counterInstance = counter({ selectsInstances })
  // console.log('modalsInstance', modalsInstance);

  var sortInstance = sort({ selectsInstances })
  // console.log('modalsInstance', modalsInstance);
})
