export function sendXmlReqService({ onSuccess, onError }) {
  if (!this.isValid) return console.log("Form is invalid. Invalid fields", this.invalidFields)

  const action = this.$form.getAttribute("action")
  const method = this.$form.getAttribute("method")
  const body = new FormData(this.$form)

  // === Ajax request
  const xhr = new XMLHttpRequest()
  xhr.open(method, action)

  xhr.onreadystatechange = () => {
    if (xhr.status >= 400) {
      onError(xhr)
      return
    }

    onSuccess(xhr)
  }

  xhr.send(body)
}
