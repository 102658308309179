function checkbox() {
  const isChecked = this.$checkbox.checked

  if (!isChecked) {
    this.isValid = false
    this.$error.innerText = "Please select this field"
    return
  }

  this.isValid = true
}

export { checkbox }
