

const select = () => {
  const selects = document.querySelectorAll(".js-select")
  if (!selects.length) return []

  // System variables
  const selects_init = []

  // Methods
  function open() {
    this.$select.classList.add("active")
    this.isOpen = true
  }

  function close() {
    this.$select.classList.remove("active")
    this.isOpen = false
  }

  function toggle() {
    this.isOpen ? this.close() : this.open()
  }

  function buildFilterItems() {}

  function onSelect(event) {
    const { currentTarget } = event
    const targetValueText = currentTarget.innerText
    const targetValue = currentTarget.dataset?.value
    const $triggerValue = this.$trigger.querySelector(".js-select-trigger-value")

    const catalogLocation = document.querySelector(".catalog__sort-location__list")
    const catalogLocationInput = document.querySelector(".catalog__sort-location-input")
    const catalogLocationItem = catalogLocation?.getElementsByClassName("catalog__sort-location__list-item")

    if (!targetValue) {
      return console.warn("Clicked button element does not have attribute data-value", currentTarget)
    }

    if ($triggerValue.closest(".select--location")) {
      if (!this.multiselectValues.includes(targetValue)) {
        this.multiselectValues.push(targetValue)
      }

      const buildItems = () => {
        if (catalogLocationInput) {
          catalogLocationInput.value = this.multiselectValues.join(";")
          this.sortOnChange()
        }

        const selectetItemsElements = this.multiselectValues.map((mapItem) => {
          const catalogLocationLi = document.createElement("li")
          const catalogLocationLiBtn = document.createElement("button")
          catalogLocationLi.className = "catalog__sort-location__list-item"
          catalogLocationLiBtn.className = "catalog__sort-location__list-item__btn"
          catalogLocationLiBtn.innerText = "x"

          catalogLocationLi.innerText = mapItem
          catalogLocationLi.append(catalogLocationLiBtn)



          catalogLocationLiBtn.onclick = () => {

            this.multiselectValues = this.multiselectValues.filter((filterItem) => filterItem !== mapItem)

            buildItems()
          }

          return catalogLocationLi
        })

        catalogLocation.innerHTML = ""

        selectetItemsElements.forEach((item) => {
          catalogLocation.append(item)
        })
      }

      buildItems()
    }

    if ($triggerValue) {
      $triggerValue.innerHTML = targetValueText
    } else {
      this.$trigger.innerHTML = targetValueText
    }

    if (this.$input) {
      this.$input.value = targetValue
    }

    this.close()

    //Add in modules
    if (this.recountOnChange) {
      this.recountOnChange()
    }

    if (this.sortOnChange) {
      this.sortOnChange()
    }


  }

  const init = ($select, index) => {
    $select.id = $select.id || `select_${index}`

    const select = {}
    select.$select = $select
    select.$trigger = $select.querySelector(".js-select-trigger")
    select.$dropdown = $select.querySelector(".js-select-dropdown")
    select.id = $select.id
    select.type = $select.dataset.type || "dropdown"
    select.isOpen = false
    select.open = open.bind(select)
    select.close = close.bind(select)
    select.toggle = toggle.bind(select)
    select.multiselectValues = []

    if (!select.$trigger) {
      return console.warn(`Can not initialize select ${select.$select} with out trigger`)
    }

    if (!select.$dropdown) {
      return console.warn(`Can not initialize select ${select.$select} with out dropdown list`)
    }

    if (select.type === "select") {
      select.$input = $select.querySelector(".js-select-input")
      select.buttons = $select.querySelectorAll(".js-select-btn")
      select.onSelect = onSelect.bind(select)

      if (!select.buttons.length) {
        return console.warn("Your select buttons list is empty in select component", select.$select)
      }

      if (!select.$input) {
        console.warn(
          'Your select component does not have any registered input element with class ".js-select-input"',
          select.$select
        )
      }

      select.buttons.forEach(($btn) => $btn.addEventListener("click", select.onSelect))
    }

    select.$trigger.addEventListener("click", select.toggle)
    selects_init.push(select)
  }

  // Init
  selects.forEach(init)

  window.addEventListener("click", ({ target }) => {
    selects_init.forEach((select) => {
      if (!select.$select.contains(target)) {
        select.close()
      }
    })
  })

  return selects_init
}

export { select }
