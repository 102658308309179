import { minmaxLength } from "../lib/minmax-length"

function tel() {
  const regExp = new RegExp("[^0-9() +-]")
  const isEmpty = this.$input.value !== ""
  const { isMinMaxLengthValid } = minmaxLength(this.$input)

  if (!isEmpty) {
    this.isValid = false

    this.$error.innerText = "This field must not be empty"
    return
  }

  if (this.$input.value.match(regExp)) {
    this.isValid = false

    this.$error.innerText = "The field must contain only numbers"
    return
  }

  if (!isMinMaxLengthValid) {
    this.isValid = false
    this.$error.innerText = "The field must contain at least 8 digits"
    return
  }

  this.isValid = true
}

export { tel }
