import { minmaxLength } from "../lib/minmax-length"

/*
 * regExp will validate:
 * - One upper charter
 * - One number charter
 * - One special charter
 */

function password() {
  const regExp = new RegExp("^(?=.*[A-Z])(?=.*\\d)(?=.*[@#$%^&+=])")
  const isEmpty = this.$input.value === ""
  const { isMinMaxLengthValid } = minmaxLength(this.$input)

  if (isEmpty) {
    this.isValid = false
    return
  }

  if (!regExp.test(this.$input.value)) {
    this.isValid = false
    return
  }

  if (!isMinMaxLengthValid) {
    this.isValid = false
    return
  }

  this.isValid = true
}

export { password }
