// Counter

const counter = ({ selectsInstances }) => {
  totalUsdPrice(calcCartPrice())

  const $productContent = document.querySelector(".product__content")

  if (!$productContent) return

  function recountOnChange() {
    totalUsdPrice(calcCartPrice())
  }

  const currentSelects = selectsInstances.filter((select) => {
    return $productContent.contains(select.$select)
  })

  currentSelects.forEach((select) => {
    select.recountOnChange = recountOnChange.bind(select)
  })

  calcSelectPrices()

  const clickHandle = (event) => {
    const productCount = event.target?.closest(".product__counter"),
      counter = productCount?.querySelector("[data-counter]")

    if (event.target.dataset.action === "plus") {
      if (parseInt(counter.value) < counter.max) {
        counter.value = ++counter.value
        totalUsdPrice(calcCartPrice())
      }
    }

    if (event.target.dataset.action === "minus") {
      if (parseInt(counter.value) > counter.min) {
        counter.value = --counter.value
        totalUsdPrice(calcCartPrice())
      }
    }

    if (event.target.dataset.counter == "") {
      counter.addEventListener("input", (event) => {
        if (parseInt(counter.value) > counter.max) {
          counter.value = counter.max
        }

        if (parseInt(counter.value) < counter.min || counter.value == "") {
          counter.value = 1
        }

        totalUsdPrice(calcCartPrice())
      })
    }
  }

  const productCount = document.querySelectorAll(".product__counter")

  productCount.forEach((item) => {
    item.addEventListener("click", clickHandle)
  })

  function getPriceNumber(price) {
    let string = ""
    for (let index in price) {
      if (isFinite(price[index]) || price[index] == ".") {
        string += price[index]
      }
    }
    return parseFloat(string)
  }

  function calcCartPrice() {
    const cartsItem = document.querySelectorAll(".product__content")

    let totalPrice = 0

    cartsItem.forEach((item) => {
      const amountEl = item.querySelector("[data-counter]")
      const price = item.querySelector("[data-price]")

      const currentPrice =
        getPriceNumber(amountEl.value) * getPriceNumber(price.innerText) + calcSelectPrices() * amountEl.value
      totalPrice += currentPrice
    })

    return Number.isInteger(totalPrice) ? totalPrice : totalPrice.toFixed(2)
  }

  function totalUsdPrice(totalPrice) {
    const totalCount = document.querySelector("[data-total]")

    if (totalCount) {
      totalCount.innerText = `$ ${totalPrice}`
    }
  }

  function calcSelectPrices() {
    const selectPrice = document.querySelectorAll(".field__input")

    let price = 0
    selectPrice.forEach((item) => {
      price += getPriceNumber(item.value)
    })

    return price
  }
}

export { counter }
