const sortObject = {
  category: "all",
  location: []
}

const sort = ({ selectsInstances }) => {
  const $eventsItem = document?.querySelectorAll(".events__item")
  const $catalog = document?.querySelector(".catalog")
  const $catalogNav = document?.querySelectorAll("[data-nav]")

  function catalogNavRemoveClass() {
    $catalogNav.forEach((item) => {
      item.classList.remove("active")
    })
  }

  const handleSort = () => {
    return [...$eventsItem]
      .filter((item) => {
        const category = item.dataset?.category?.toLowerCase()

        if (sortObject.category === "all") {
          return true
        }

        return sortObject.category === category
      })
      .filter((item) => {
        const location = item.dataset?.location.toLowerCase()

        if (sortObject.location.length) {
          return sortObject.location.includes(location)
        }

        return true
      })
  }

  const appendCardsHandle = () => {
    const sortedItems = handleSort()

    const $eventsInner = document?.querySelector(".events__inner")
    $eventsInner.innerHTML = ""

    currentSelects.forEach((item) => {
      if (item.$input.value == "Price") {
        sortByPrice(sortedItems)
      }

      if (item.$input.value == "Alfabet") {
        sortByAlfabet(sortedItems)
      }

      if (item.$input.value == "Date") {
        sortByDate(sortedItems)
      }
    })

    sortedItems.forEach((item) => {
      const eventsCol = document.createElement("div")
      eventsCol.classList.add("events__col")
      eventsCol.classList.add("events__col-width")
      eventsCol.append(item)
      $eventsInner.append(eventsCol)
    })
  }

  function sortByCategory() {
    $catalogNav.forEach((btn) => {
      if (btn.classList.contains("active")) {
        const categoryValue = btn.dataset?.nav

        sortObject.category = categoryValue.toLowerCase()
      }
    })
  }

  function catalogListValueSpy() {
    const $catalogListItem = document?.querySelector(".catalog__sort-location-input")

    let value = $catalogListItem.value.toLowerCase().split(";")

    return value[0] !== "" ? value : []
  }

  function sortByLocation() {
    const values = catalogListValueSpy()

    sortObject.location = values
  }

  function sortByPrice($sortedItems) {
    const sortedItems = $sortedItems.sort((a, b) => {
      const aPrice = +a.dataset.price
      const bPrice = +b.dataset.price

      return aPrice - bPrice
    })
  }

  function sortByAlfabet($sortedItems) {
    const sortedItems = $sortedItems.sort((a, b) => {
      const aCategory = a.dataset.category
      const bCategory = b.dataset.category

      if (aCategory < bCategory) return -1
      if (aCategory > bCategory) return 1
      return 0
    })
  }

  function sortByDate($sortedItems) {
    const sortedItems = $sortedItems.sort((a, b) => {
      const aCategory = new Date(a.dataset.time)
      const bCategory = new Date(b.dataset.time)

      if (aCategory < bCategory) return -1
      if (aCategory > bCategory) return 1
      return 0
    })
  }

  // Init
  $catalogNav.forEach((item) => {
    item.addEventListener("click", (event) => {
      event.preventDefault()

      catalogNavRemoveClass()

      item.classList.add("active")

      sortOnChange()
    })
  })

  function sortOnChange() {
    sortByCategory()
    sortByLocation()

    appendCardsHandle()
  }

  const currentSelects = selectsInstances?.filter((select) => {
    return $catalog?.contains(select.$select)
  })

  currentSelects.forEach((select) => {
    select.sortOnChange = sortOnChange.bind(select)
  })
}

export { sort }
